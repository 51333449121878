var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"staging-20241115144103-7cde616"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { getUids } from '~/utils/getUids';
import { ignoreErrors } from '~/utils/sentry';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'local',

    // Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,

    tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 1.0,

    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        mutationBreadcrumbLimit: 1000,
        mutationLimit: 1000,
        networkDetailAllowUrls: [process.env.NEXT_PUBLIC_GRAPHQL_API_URL],
        networkCaptureBodies: true,
        networkRequestHeaders: [
          'x-uid-client',
          'x-uid-tab',
          'x-uid-pageload',
          'x-uid-request',
          'x-uid-visitor',
          'x-reporting-metadata'
        ],
        networkResponseHeaders: [
          'x-uid-client',
          'x-uid-tab',
          'x-uid-pageload',
          'x-uid-request',
          'x-uid-visitor',
          'x-reporting-metadata',
          'x-amzn-requestid'
        ]
      })
    ],

    // Ignore specific expected errors client side to reduce number of errors send
    // to sentry so we don't run into rate limits
    ignoreErrors,

    beforeSend: (event) => {
      const uids = getUids();

      event.tags = event.tags || {};
      event.tags['uid-client'] = event.extra?.['x-uid-client'] || uids.client;
      event.tags['uid-tab'] = event.extra?.['x-uid-tab'] || uids.tab;
      event.tags['uid-pageload'] = event.extra?.['x-uid-pageload'] || uids.pageload;
      event.tags['uid-visitor'] = event.extra?.['x-uid-visitor'] || uids.visitor;
      event.tags['uid-request'] = event.extra?.['x-uid-request'] || undefined;

      event.contexts = event.contexts || {};
      event.contexts.runtimeContext = {
        environment: typeof window !== 'undefined' ? 'client' : 'server'
      };

      return event;
    }
  });
}
